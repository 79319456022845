const riot = require('riot');

riot.tag2('modal-note-histories', '<div class="relative f bg-background_light_secondary w80per h80per rounded-8 overflow-hidden s-s90per" ref="modal"> <button class="absolute t8 r8 f fh circle s28 bg-gray opacity-80 cursor-pointer z3 s-show" type="button" onclick="{close}"><i class="icon-close opacity-100 p6 text-white fs24"></i></button> <div class="col3 overflow-y-scroll s-col2"> <div class="p16 hover-trigger hover-bg-white cursor-pointer {&quot;bg-white&quot;: history === current}" each="{history in histories}" onclick="{onSelect}"> <div class="fs16 font-lato mb8"><span class="mr4">{dayjs(history.data.updated_at).format(\'YYYY/MM/DD HH:mm:ss\')}</span><span class="fs12 text-gray">({dayjs(history.data.updated_at).fromNow()})</span></div> <div class="f fm"><img class="s24 circle flex-fixed object-fit-cover mr4" riot-src="{app.utils.getImageUrl(history.relation.user.data.icon_image)}"> <div class="text-gray fs12">{history.relation.user.data.display_name}</div> </div> </div> </div> <div class="col9 bg-white p16 relative f flex-column s-col10 s-px4"> <div class="s-full overflow-hidden mb8"> <div class="s-full" show="{!isDiff}" ref="editor" data-is="atom-editor"></div> <div class="pb80" show="{isDiff}" ref="diff"></div> </div> <div class="flex-fixed f fr"> <button class="button primary" onclick="{toggleDiff}">{isDiff ? ⁗Preview⁗ : ⁗Diff⁗}</button> </div> </div> </div>', 'modal-note-histories,[data-is="modal-note-histories"]{background-color:rgba(28,34,54,0.9)} modal-note-histories .diff-container,[data-is="modal-note-histories"] .diff-container{max-height:80vh;overflow:auto;overflow-y:scroll}', 'class="f fh"', function(opts) {
    this.isDiff = false;

    this.diffConfiguration = {
      drawFileList: false,
      fileListToggle: true,
      fileListStartVisible: true,
      fileContentToggle: false,
      matching: 'lines',
      outputFormat: 'side-by-side',
      synchronisedScroll: true,
      highlight: true,
      renderNothingWhenEmpty: false,
    };

    this.on('mount', async () => {
      this.histories = await this.opts.note.fetchHistories();
      this.update();

      this.isDiff = !!this.opts.isDiff;

      this.setCurrent(this.histories[0]);

      this.refs.editor.editor.setOption('readOnly', true);
    });

    this.onSelect = (e) => {
      this.setCurrent(e.item.history);
    };

    this.setCurrent = (history) => {
      if (!history) return ;

      this.current = history;

      this.refs.editor.setValue(history.data.content);
      this.update();

      var index = this.histories.indexOf(this.current);
      this.prevHistory = this.histories[index+1] || {data: {title:'', content: ''}};

      const patch = JsDiff.createPatch('', this.prevHistory.data.content, this.current.data.content, this.prevHistory.data.title, this.current.data.title);
      const diff2htmlUi = new Diff2HtmlUI(this.refs.diff, patch, this.diffConfiguration);
      this.refs.diff.classList.add('diff-container');
      diff2htmlUi.draw();
    };

    this.toggleDiff = () => {
      this.isDiff = !this.isDiff;
    };
});